import React, { useState } from 'react'

import { Flex } from '../components'
import { Button, TextField } from '@material-ui/core'
import styled from 'styled-components'

const Wrap = styled.div`
  padding: 80px 20px;
  max-width: 800px;
  margin: 0 auto;
`

const Field = styled.div`
  padding: 10px;
`

function View() {
  const [uploading, setUploading] = useState(false)
  const [form, setForm] = useState({})
  const [message, setMessage] = useState()
  
  function encode(data) {
    return Object.keys(data)
      .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
      .join('&');
  }

  async function submit(e){
    e.preventDefault()
    setUploading(true)
    await fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({ 'form-name': 'feature', ...form }),
    })
    setUploading(false)
    setMessage('Sent Successfully!')
  }

  function updateField(e){
    form[e.target.name] = e.target.value
    setForm(form)
  }

  return (
    <Wrap>
      <h1>Feature Request</h1>

      <form onSubmit={submit} name="feature" action="/" method="POST" data-netlify="true">
        {message ? (
          <p style={{ textAlign: 'center' }}>{message}</p>
        ) : (
          <>
            <Field>
              <TextField fullWidth label="Name" type="text" name="name" variant="outlined" onChange={updateField} />
            </Field>
            
            <Field>
              <TextField fullWidth label="Email" type="email" name="email" variant="outlined" onChange={updateField} />
            </Field>
            
            <Field>
              <TextField fullWidth label="Feature" name="feature" multiline rows={4} variant="outlined" onChange={updateField} />
            </Field>
          
            {uploading ? (
              <Field>
                <Button variant="contained" color="primary">Sending...</Button>
              </Field>
            ) : (
              <Field>
                <Button type="submit" variant="contained" color="primary">Send</Button>
              </Field>
            )}
          </>
        )}
      </form>
    </Wrap>
  );
}

export default View;
