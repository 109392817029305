import React, { useState } from 'react'

import { Flex } from '../components'
import { Button, LinearProgress } from '@material-ui/core'
import styled from 'styled-components'

const Hero = styled.div`
  width: 100%;
  background: #F5F6FD;
  h1 {
    font-weight: 600;
    letter-spacing: -2px;
    font-size: 44px;
  }
  h2 {
    margin-top: 35px;
    font-size: 18px;
    font-weight: 400;
  }
  button {
    margin-right: 16px;
  }
`

const Section = styled.div`
  width: 100%;
  background: #F5F6FD;
  padding: 120px 20px;

  h3 {
    font-size: 32px;
    text-align: center;
  }
  p {
    font-size: 18px;
    line-height: 32px;
    margin-top: 24px;
    text-align: center;
  }
`

const Phones = styled.img`
  width: 600px;
  max-width: 100%;
`

const Bar = styled.div`
  padding: 20px;
  background: #2B3D53;

  p {
    color: white;
  }
`

const Number = styled.p`
  font-size: 44px;
  font-weight: 900;
  color: white;
  margin-right: 16px;
`

const Phone = styled.img`
  max-width: 300px;
  margin: 0 16px;
`

function View() {
  const [uploading, setUploading] = useState(false)
  
  return (
    <div>
      <Hero>
        <Flex align="center" justify="center" width="100%" wrap>
          <div>
            <h1>Garden Pro Planner</h1>
            <h2>The Ultimate garden planning app</h2>

            <Flex padding="32px 0 0">
              <a target="_blank" href="https://apps.apple.com/us/app/garden-pro-planner/id1539031278"><Button variant="contained" color="primary">Download Free</Button></a>
              {/* <Button variant="outlined" color="primary">See Features</Button> */}
            </Flex>
          </div>

          <Phones src="/phones.png" />
        </Flex>
      </Hero>

      <Bar>
        <Flex justify="center" align="center">
          <Number>$600</Number>
          <p>Average annual savings per year when maintaining your own home garden.</p>
        </Flex>
      </Bar>

      <Section>
        <Flex justify="space-between" width="1000px" align="center">
          <Flex column>
            <h3>Is your vegetable garden struggling?</h3>
            <p>The Garden Pro Planner app will help you organize and plan like a pro!<br/>Don't waste an entire season doing it wrong, we can help.</p>
          </Flex>

          <Phone src="./garden.png" />
        </Flex>
      </Section>

      <Bar>
        <Flex justify="center" align="center">
          <Number>40%</Number>
          <p>Of all Americans grow and maintain a vegetable garden.</p>
        </Flex>
      </Bar>

      <Section>
        <h3>Our app has all the information you need</h3>
        <p>Researching each plant for your garden can be overwhelming and time consuming. <br /> We have all the information you need in one place!</p>

        <Flex justify="center" padding="32px 0 0">
          <Phone src="./details.png" />
          <Phone src="./search.png" />
          <Phone src="./filter.png" />
        </Flex>
      </Section>
      
      <div id="disqus_thread"></div>
    </div>
  );
}

export default View;
