const Contentful = require("contentful");

const client = Contentful.createClient({
  space: "q0yw5q9wwku1",
  accessToken: "rvnSM3hcNUoqYbe8sbaC1vlhmNHc2oXrfcnN6bcwILQ",
});

module.exports = {
  entry(id) {
    return client.getEntry(id);
  },
  query(query) {
    return client.getEntries(query);
  }
};
