import React, { useState, useEffect } from 'react'
import { Button, LinearProgress } from '@material-ui/core'
import styled from 'styled-components'

import { Flex } from '../components'
import contentful from '../util/contentful'

const Background = styled.div`
  background: #F5F6FD;
  min-height: 100vh;

  a {
    display: block;
    width: 100%;
  }
`

const Title = styled.h1`
  font-size: 48px;
  font-weight: 600;
`

const Article = styled.div`
  padding: 30px;
  background: white;
  border-radius: 8px;
  margin-top: 16px;
  width: 100%;
  font-size: 18px;
`

function View() {
  const [articles, setArticles] = useState()
  
  async function getArticles(){
    const _articles = await contentful.query({content_type: 'article', limit: 1000})
    setArticles(_articles.items)
    console.log('articles', _articles)
  }

  useEffect(() => {
    getArticles()
  }, [])
  
  if (!articles) return <Background />
  return (
    <Background>
      <Flex padding="80px 20px" margin="0 auto" maxWidth="800px" column>
        <Title>Articles</Title>

        {articles.map((article) => (
          <a key={article.sys.id} href={`/article/${article.sys.id}/${encodeURIComponent(article.fields.title.replace(/ /g, '-'))}/`}>
            <Article>
              {article.fields.title}
            </Article>
          </a>
        ))}
      </Flex>
    </Background>
  );
}

export default View;
