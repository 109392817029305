import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import ReactMarkdown from 'react-markdown'
import { Button, LinearProgress } from '@material-ui/core'
import styled from 'styled-components'

import { Flex } from '../components'
import contentful from '../util/contentful'

const Background = styled.div`
  background: #F5F6FD;
  min-height: 100vh;
`

const Title = styled.h1`
  font-size: 48px;
  margin-bottom: 32px;
  font-weight: 600;
  border-bottom: 1px solid #ddd;

  @media screen and (max-width: 600px) {
    font-size: 24px;
  }
`

const SubTitle = styled.div`
  font-size: 20px;
  margin: 48px 0 24px;
`

const Question = styled.h3`
  font-weight: 600;
  font-size: 18px;
  margin: 0 0 8px;
`

const Answer = styled.p`
  line-height: 1.8em;
  white-space: pre-line;
  margin-bottom: 32px;
`

const Markdown = styled.div`
  h1, h2, h3 {
    margin-top: 24px;
    font-size: 20px;
    font-weight: 600;
    text-transform: uppercase;
  }
  p {
    line-height: 1.6em;
    margin-top: 12px;
  }
  img {
    max-width: 100%;
    border-radius: 22px;
    margin-top: 12px; 
  }
  a {

    color: #1551d8;
    text-decoration: underline;
  }
`

function View() {
  const [article, setArticle] = useState()
  const [schema, setSchema] = useState({})
  const { id } = useParams()
  
   async function getArticle(){
    const _article = await contentful.entry(id)
    if(_article.fields.qa && _article.fields.qa.length > 0) {
      const _schema = {
        "@context": "https://schema.org",
        "@type": "FAQPage",
        "mainEntity": []
      }
      for(var i in _article.fields.qa){
        const obj = {
          "@type": "Question",
          "name": _article.fields.qa[i].fields.question,
          "acceptedAnswer": {
            "@type": "Answer",
            "text": _article.fields.qa[i].fields.answer
          }
        }
        _schema.mainEntity.push(obj)
      }
      console.log('schema', _schema)
      setSchema(_schema)
    }
    if(_article) setArticle(_article.fields)
   }

  useEffect(() => {
    getArticle()
  }, [])

  if (!article || !schema) return null
  return (
    <>
      <Helmet>
        <title>{article.title}</title>
        <script className='structured-data-list' type="application/ld+json">{`${JSON.stringify(schema)}`}</script>
      </Helmet>

      <Background>
        <Flex margin="0 auto" maxWidth="800px" padding="32px 20px" column>
          <Title>{article.title}</Title>

          {article.article && (
            <Markdown>
              <ReactMarkdown source={article.article}/>
            </Markdown>
          )}

          {article.qa && article.qa.length > 0 && (
            <>
              <SubTitle>Questions and Answers</SubTitle>
              {article.qa.map(question => (
                <Flex key={question.sys.id} column>
                  <Question>{question.fields.question}</Question>
                  <Answer>{question.fields.answer}</Answer>
                </Flex>
              ))}
            </>
          )}
        </Flex>
      </Background>
    </>
  );
}

export default View;
