import React from 'react'
import styled, { css } from 'styled-components'

const Flex = styled.div`
  display: flex;
  padding: ${({padding}) => padding || `0`};
  margin: ${({margin}) => margin || `0`};
  justify-content: ${({justify}) => justify || `flex-start`};
  align-items: ${({align}) => align || `flex-start`};
  max-width: ${({maxWidth}) => maxWidth || `inherit`};
  width: ${({width}) => width || `auto`};

  ${({column}) => column && css`
    flex-direction: column;
  `}

  ${({wrap}) => wrap && css`
    flex-wrap: wrap;
  `}

  ${({width}) => width && css`
    margin: 0 auto;
  `}
`

const Controller = (props) => {

  return (
    <Flex {...props}/>
  )
}

export default Controller