import React from 'react'
import { Link } from "react-router-dom";
import styled, { css } from 'styled-components'
import { Button, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import Flex from './Flex'

const Nav = styled.div`
  background: white;
  padding: 8px 80px;

  @media screen and (max-width: 600px) {
    padding: 8px 20px;
  }
`

const Logo = styled.p`
  font-size: 18px;
  font-weight: 600;
`

const Desktop = styled(Flex)`
  @media screen and (max-width: 600px) {
    display: none;
  }
`

const useStyles = makeStyles((theme) => ({
  margin: {
    margin: theme.spacing(1),
  },
  extendedIcon: {
    marginRight: theme.spacing(1),
  },
}));

const Controller = (props) => {
  const classes = useStyles();

  return (
    <Nav>
      <Flex justify="space-between" align="center">
        <Flex>
          <Link to="/">
            <Logo>Garden Pro Planner</Logo>
          </Link>
        </Flex>

        <Flex justify="flex-end">
          <Desktop justify="flex-end">
            <Link to="/feature-request"><Button color="primary" className={classes.margin}>Contact</Button></Link>
            {/* <Link to="/about-us"><Button color="primary" className={classes.margin}>About Us</Button></Link>
            <Link to="/features"><Button color="primary" className={classes.margin}>Features</Button></Link>
            */}
            <Link to="/articles"><Button color="primary" className={classes.margin}>Articles</Button></Link>
          </Desktop>
          <a target="_blank" href="https://apps.apple.com/us/app/garden-pro-planner/id1539031278"><Button variant="outlined" color="primary" className={classes.margin}>Download App</Button></a>
        </Flex>
      </Flex>
    </Nav>
  )
}

export default Controller